import apiClient from "axios";
import appConfig from "../../config/app";

//axios instance
const axios = apiClient.create({
  baseURL: `${appConfig.app.connectorAppApi}`,
  headers: {
    "Content-Type": "application/json",
  },
});
// Request interceptor to add the token to headers
axios.interceptors.request.use(
  (config) => {
    let user = JSON.parse(localStorage.getItem("user-info"));
    const ssoUser = user.ssoUser;
    let token;
    if (ssoUser) {
      token = user.tokens.access_token;
    } else {
      token = user.tokens.accessToken.token;
    }
    if (token) {
      config.headers.Authorization = token;
      config.headers["ssoUser"] = ssoUser ? "true" : "false";
    }
    return config;
  },
  (error) => Promise.reject(error)
);
// Response interceptor to handle errors
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      if (error.response.data.message === "REFRESH_TOKEN_EXPIRED") {
        window.open(
          `${process.env.VUE_APP_AUTHFAST_URL}/logout/client?redirect_url=${process.env.VUE_APP_REDIRECT_URI}`,
          "_self"
        );
      }
      return refreshTokenAndRetry(error);
    }
    return Promise.reject(error);
  }
);
// Function to refresh the token and retry the original request
async function refreshTokenAndRetry(error) {
  try {
    let user = JSON.parse(localStorage.getItem("user-info"));
    const ssoUser = user.ssoUser;
    let refreshToken;
    if (ssoUser) {
      refreshToken = user.tokens.refresh_token;
    } else {
      refreshToken = user.tokens.refreshToken.token;
    }
    let payload = {
      token: refreshToken,
      ssoUser: ssoUser ? "true" : "false",
    };
    let response = await axios.post(
      `${appConfig.app.connectorAppApi}/users/refresh-token`,
      payload
    );
    response = response.data;
    localStorage.setItem(
      "user-info",
      JSON.stringify({
        ssoUser: response.ssoUser,
        tokens: response.data,
      })
    );
    let newAccessToken = ssoUser
      ? user.tokens.access_token
      : user.tokens.accessToken.token;
    error.config.headers.Authorization = newAccessToken;
    return;
  } catch (refreshError) {
    return Promise.reject(refreshError);
  }
}
export default axios;
