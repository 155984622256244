<template>
  <div v-loading="loading" class="total">
    <el-row :gutter="80">
      <el-col
        :xl="{ span: 11 }"
        :lg="{ span: 12 }"
        :md="{ span: 24 }"
        :sm="{ span: 24 }"
      >
        <BannerComponent></BannerComponent>
      </el-col>
      <el-col
        :xl="{ span: 13 }"
        :lg="{ span: 12 }"
        :md="{ span: 24 }"
        :sm="{ span: 24 }"
      >
        <div>
          <div style="margin-top: 30px">
            <div class="heading" style="color: darkgray">
              Don't you have an account?
              <a
                :href="logoutUrl"
                style="
                  text-decoration: none;
                  cursor: pointer;
                  color: rgb(25, 137, 250);
                "
              >
                Sign up
              </a>
            </div>
            <div class="container" style="width: 100%">
              <div class="container-In">
                <div style="margin-bottom: 30px">
                  <h1 class="fs-2 text-dark pb-2">Login</h1>
                  <p style="color: darkgray; font-weight: 100">
                    Greetings!<br />
                    Please enter your credentials
                  </p>
                  <hr />
                </div>
                <el-form
                  label-position="top"
                  :rules="formRules"
                  :model="userDetails"
                >
                  <el-form-item
                    prop="email"
                    label="Email"
                    class="el-form-items"
                  >
                    <el-input
                      style="
                        height: 60px;
                        font-size: 1.15em;
                        letter-spacing: 0.075em;
                      "
                      placeholder="Enter your Email"
                      v-model="userDetails.email"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="password"
                    prop="password"
                    class="el-form-items"
                  >
                    <el-input
                      :type="Show ? 'text' : 'password'"
                      style="
                        height: 60px;
                        font-size: 1.15em;
                        letter-spacing: 0.075em;
                      "
                      placeholder="Enter your password"
                      v-model="userDetails.password"
                    >
                      <template #append>
                        <el-button @click="togglePasswordVisibility()"
                          ><el-icon><View /></el-icon
                        ></el-button>
                      </template>
                    </el-input>
                  </el-form-item>
                  <el-form-item class="el-form-items">
                    <el-col :span="24">
                      <el-button
                        type="text"
                        class="forgot-button"
                        @click="goToForgotPassword"
                        >Forgot Password?</el-button
                      >
                    </el-col>
                  </el-form-item>
                  <el-form-item class="el-form-items">
                    <el-button
                      type="primary"
                      style="
                        height: 60px;
                        background-color: hotpink;
                        width: 100%;
                        font-weight: bold;
                        font-size: 16px;
                      "
                      @click="submitForm"
                      :loading="submitLoading"
                      >Log in</el-button
                    >
                  </el-form-item>
                  <el-form-item class="el-form-items">
                    <el-button
                      type="primary"
                      style="
                        background-color: #1565d8;
                        border: none;
                        height: 50px;
                        width: 100%;
                        font-weight: bold;
                        font-size: 16px;
                      "
                      @click="ssoLogin"
                      >Sign In With Nimble SSO</el-button
                    >
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import store from "../store/index";
import { mapGetters } from "vuex";
import BannerComponent from "../components/BannerComponent.vue";
export default {
  data() {
    return {
      Show: false,
      submitLoading: false,
      userDetails: {
        email: null,
        password: null,
      },
      formRules: {
        email: [
          { required: true, message: "Email is required", trigger: "blur" },
        ],
        password: [
          { required: true, message: "Email is required", trigger: "blur" },
        ],
      },
      userResponse: null,
      loading: false,
    };
  },
  components: {
    BannerComponent,
  },
  computed: {
    ...mapGetters("user", ["getLoginResponse"]),
    logoutUrl() {
      return `${process.env.VUE_APP_AUTHFAST_URL}/signup/client?redirect_url=${process.env.VUE_APP_REDIRECT_URI}`;
    },
  },
  mounted() {
    localStorage.removeItem("user-info");
  },
  methods: {
    async submitForm() {
      if (this.userDetails.email && this.userDetails.password) {
        this.submitLoading = true;
        try {
          await store.dispatch("user/userLogin", this.userDetails);
          this.submitLoading = false;
          if (this.getLoginResponse.success) {
            this.$notify({
              title: "Success",
              message: this.getLoginResponse.message,
              type: "success",
            });
            localStorage.setItem(
              "user-info",
              JSON.stringify({
                ssoUser: false,
                tokens: this.getLoginResponse.data,
              })
            );
            this.$router.push(this.$route.query.redirect || "/connector");
          } else {
            this.$notify({
              title: "Error",
              message: this.getLoginResponse.data.message
                ? this.getLoginResponse.data.message
                : "Login Failed",
              type: "error",
            });
          }
        } catch (err) {
          this.submitLoading = false;
        }
      }
    },
    goToForgotPassword() {
      this.$router.push("/forgotPassword");
    },
    togglePasswordVisibility() {
      this.Show = !this.Show;
    },
    ssoLogin() {
      window.open(
        `${process.env.VUE_APP_AUTHFAST_URL}/signin/client?redirect_url=${process.env.VUE_APP_REDIRECT_URI}`,
        "_self"
      );
    },
  },
};
</script>
<style scoped>
.total {
  margin-top: -60px;
  padding: 0px;
  width: 95vw !important;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 90px;
}
.container-In {
  width: 60%;
  @media (max-width: 768px) {
    width: 95% !important;
  }
}
.forgot-button {
  float: right;
}
.heading {
  margin-left: 50%;
}
@media screen and (max-width: 360px) {
  .form-content {
    width: 100%;
  }

  .heading {
    margin-left: 0;
    font-size: 14px;
  }
  .container-In {
    width: 100%;
  }
  h1 {
    font-size: 18px;
  }

  p {
    font-size: 14px;
  }

  .input-field,
  .login-button,
  .sso-button {
    height: 40px;
    font-size: 12px;
  }

  .forgot-button {
    font-size: 14px;
    margin-right: 0;
  }
}

.banner-container {
  height: 100vh !important;
}
</style>
