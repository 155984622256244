<template>
  <el-row class="drawer_slide">
    <el-col :span="4">
      <el-button circle class="drawer-logo">
        <img :src="step.app_id.logo" width="39" height="39" alt="App Logo" />
      </el-button>
    </el-col>
    <el-col :span="20">
      <div class="drawer-title">
        {{ step.app_id.app_name_label || "Unknown App" }}
      </div>
      <div class="drawer-title-p">
        You can oversee all your secure connections.
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  props: {
    step: {
      type: Object,
      required: true,
    },
  },
};
</script>
