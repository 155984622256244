<template>
  <div></div>
</template>
<script>
import store from "@/store/index";
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("sso", ["getSsoLoginResponse"]),
  },
  async mounted() {
    this.extractQueryFromUrl();
  },
  methods: {
    async extractQueryFromUrl() {
      try {
        let query = this.$route.query;
        if (query.logout) {
          localStorage.removeItem("user-info");
          window.open(
            `${process.env.VUE_APP_AUTHFAST_URL}/signin/client?redirect_url=${process.env.VUE_APP_REDIRECT_URI}`,
            "_self"
          );
          return;
        }
        let details = { code: query.code, redirect_url: query.redirect_url };
        await store.dispatch("sso/ssouserLogin", details);
        if (this.getSsoLoginResponse && this.getSsoLoginResponse.data) {
          localStorage.setItem(
            "user-info",
            JSON.stringify({
              ssoUser: this.getSsoLoginResponse.ssoUser,
              tokens: this.getSsoLoginResponse.data,
            })
          );
          this.$router.push("/connector");
        } else {
          this.$router.push("/");
        }
      } catch (err) {
        console.log("Err", err);
      }
    },
  },
};
</script>
